<template>
  <el-card class="page-card">
    <sui-list
      ref="list"
      :column="tableColumn"
      :fun="tableFun"
      :req="tableFunReq"
      @backflow="backflow"
    />
  </el-card>
</template>
<script>
import SuiList from "@/components/s-ui/list";
import { GetExchateRateLists } from "@/utils/api";
import { formatDataTime } from "@/utils";
export default {
  components: { SuiList },
  data() {
    return {
      tableColumn: [
        // 货币单位
        {
          name: "currencyFrom",
          label: "content.exchaterate.from",
        },
        // 汇率
        {
          name: "rate",
          label: "content.exchaterate.rate",
        },
        // 货币
        {
          name: "currencyTo",
          label: "content.exchaterate.currencyTo",
        },
        // 创建时间
        {
          name: "createAt",
          label: "content.exchaterate.createAt",
          formatFn: formatDataTime,
          width: 110,
        },
        // 更新时间
        {
          name: "updateAt",
          label: "content.exchaterate.updateAt",
          formatFn: formatDataTime,
          width: 110,
        },
      ],
      // list的请求接口
      tableFun: GetExchateRateLists,
      // list的默认参数
      tableFunReq: {
        orderKey: "",
        pageSize: this.max || 10,
      },
      count: 0,
    };
  },
  methods: {
    // 分页
    backflow(count) {
      this.count = count;
    },
  },
};
</script>

<style lang="scss">
.hd {
  &-row {
    color: #333;
    font-weight: normal;
  }
}
.bd {
  &-row {
    text-align: center;
  }
}
page-card {
  .el-icon-upload {
    padding-top: 30px;
    .el-icon-upload {
      margin-top: 15px;
    }
  }
}
</style>